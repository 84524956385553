import {
  Flex,
  Button,
  Checkbox,
  Text,
  Box,
  Center,
  Spinner,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  VStack,
  Table,
  Tbody,
  Tr,
  Td,
  Tooltip,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Command } from 'cmdk'

import { StyledCommandItem } from '.'
import { StateStore } from '../Store'
import { Option } from '../Nodes/v2/FormSections/UpdateRecordSection'
import Api from '../../lib/api'
import { Select } from 'chakra-react-select'
import { ArrowUpRightIcon } from '@heroicons/react/24/outline'

const selectStyles = (width?: string) => ({
  control: (provided: any, state: any) => ({
    ...provided,
    boxShadow: 'none',
    fontSize: '0.875rem',
    // minWidth: '150px',
    width: width ? width : '210.66px', // Make control responsive to parent container's width
    fontWeight: '500',
    minHeight: '36px',
    padding: '0 8px',
    cursor: 'pointer',
    backgroundColor: 'white',
    marginTop: '12px',
    borderRadius: '4px', // Match your border radius
    border: '1px solid #e4e4e7',
    '&:hover': {
      border: '1px solid #e4e4e7!important',
      backgroundColor: '#fafafa',
    },
    '&:focus': { border: '1px solid  #e4e4e7 !important' },
    '&:focus-within': { border: '1px solid  #e4e4e7 !important' },
    '&:active': { border: '1px solid  #e4e4e7 !important' },
    outline: 'none',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0 6px',
    fontSize: '0.875rem',
    fontWeight: '500',
    borderRadius: '10px',
    overflow: 'hidden', // Add this to handle the overflow of elements
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: '#333', // Placeholder text color
    fontSize: '13px', // Placeholder font size
    fontWeight: '500',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    alignItems: 'center',
    position: 'relative',
    bottom: '1px',
    background: 'none !important',
    svg: {
      strokeWidth: '1px !important',
    },
    paddingLeft: '0px',
    paddingRight: '0px',
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    border: '.2px solid #E0E0E0',
    borderTop: '0px',
    boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    fontSize: '13px',
    backgroundColor: 'white',
    overflow: 'hidden',
    width: '250px',
    maxHeight: '200px',
    zIndex: 9999999,
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '200px',
    overflowY: 'auto',
    padding: 0,
  }),
  multiValue: (styles: any, { data }: any) => {
    return {
      ...styles,
      background: '#F3F3F3',
      border: '1px solid #EFEFEF',
    }
  },
  option: (provided: any, state: any) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isSelected ? 'rgb(244, 244, 244)' : 'white',
    '&:hover': {
      backgroundColor: 'rgb(244, 244, 244)',
    },
    fontSize: '13px',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: '#333',
    fontSize: '0.875rem',
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
})

type Props = {
  page: string
  selectedField: { field_name: string; object_name: string }
  onConfirm: () => void
}
const picklistValues = [
  'Prospecting',
  'Qualification',
  'Needs Analysis',
  'Value Proposition',
  'Id. Decision Makers',
  'Perception Analysis',
  'Proposal/Price Quote',
  'Negotiation/Review',
  'Closed Won',
  'Closed Lost',
]

const getGroupLabel = (name: string) => {
  switch (name) {
    case 'ValidationRule':
      return 'Validation Rule'
    case 'ApexClass':
      return 'Apex Class'
    case 'ApexTrigger':
      return 'Apex Trigger'
    default:
      return name
  }
}

export default function PicklistReferences(props: Props) {
  const { page, selectedField, onConfirm } = props

  const { dispatch } = useContext(StateStore)

  const [isLoading, setIsLoading] = useState(true)

  const [fieldReferences, setFieldReferences] = useState<
    Array<{
      groupItem: string
      items: Array<{
        groupItem: string
        total_items: number
        items: Array<{ api_name: string; label: string; url: string }>
      }>
    }>
  >([])
  const [picklistOptions, setPicklistOptions] = useState<Array<Option>>([])

  const [selectedType, setSelectedType] = useState('')

  const [selectedFieldReplacements, setSelectedFieldReplacements] = useState<{
    [key: string]: Array<{ api_name: string; label: string }>
  }>({})

  const [fetchedField, setFetchedField] = useState<any>()

  useEffect(() => {
    if (!!selectedField) {
      setIsLoading(true)

      Api.salesforce.objects.objectName.customFields
        .getCustomField({
          customFieldName: selectedField.field_name,
          objectName: selectedField.object_name,
        })
        .then(field => {
          setFetchedField(field)
          setPicklistOptions(
            field.picklist_values?.map((option: any) => ({
              value: option.value,
              label: option.value,
            })),
          )

          setSelectedType(field.picklist_values?.[0].value)
        })

      setIsLoading(false)
    } else {
      setFieldReferences([])
    }
  }, [selectedField])

  useEffect(() => {
    if (!!selectedField && !!selectedType) {
      setIsLoading(true)
      Api.salesforce.customFields
        .picklistValueReferences({
          fieldName: selectedField?.field_name,
          picklistValue: selectedType,
          objectName: selectedField?.object_name,
        })
        .then(({ references }) => {
          if (Array.isArray(references)) {
            setFieldReferences(references)
          }
          setIsLoading(false)
        })
    }
  }, [selectedField, selectedType])

  const openPage = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  if (page === 'Picklist') {
    return (
      <div
        style={{
          paddingBottom: '10px',
        }}
      >
        {isLoading ? (
          <Center h='200px' w='full'>
            <Spinner />
          </Center>
        ) : (
          <Box minH='290px' maxHeight='600px' overflowY='scroll'>
            <Table>
              <Tbody>
                <Tr>
                  <Td
                    style={{
                      fontSize: '13px',
                      color: '#1E2430',
                      fontWeight: 500,
                      width: '150px',
                      border: 'none',
                      paddingLeft: '20px',
                    }}
                    py={0}
                  >
                    Picklist Value:
                  </Td>
                  <Td>
                    <Select
                      chakraStyles={selectStyles()}
                      onChange={(e: any) => {
                        setSelectedType(e.value)
                      }}
                      isDisabled={false}
                      placeholder='Select object'
                      options={picklistOptions}
                      isSearchable={true}
                      defaultValue={picklistOptions?.find(
                        (option: any) => option.value === selectedType,
                      )}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            {fieldReferences
              .find(ref => ref.groupItem === selectedType)
              ?.items.map(fieldGroup => {
                return (
                  <Command.Group
                    key={fieldGroup.groupItem}
                    style={{ paddingTop: '8px', paddingBottom: '8px' }}
                  >
                    <Flex
                      w='full'
                      justifyContent='space-between'
                      alignItems='center'
                      pb={2}
                    >
                      <Text
                        fontSize='15px'
                        color='gray.900'
                        pl={5}
                        fontWeight={500}
                      >
                        {getGroupLabel(fieldGroup.groupItem)}
                        <Tooltip
                          label={`${fieldGroup.total_items} references`}
                          color='#1A202C'
                          backgroundColor='#F0F0F0'
                          borderRadius='6px'
                          border='solid #D9D9D9 1px'
                          fontSize='12px'
                          fontWeight={400}
                          shouldWrapChildren
                          hasArrow
                        >
                          <span
                            style={{
                              color: '#5b5a5a',
                              fontWeight: 300,
                            }}
                          >
                            &nbsp;
                            {`(${fieldGroup.total_items || 0})`}
                          </span>
                        </Tooltip>
                      </Text>
                      <Flex mr='21.5px'>
                        <Text
                          fontSize='15px'
                          color='gray.900'
                          fontWeight={400}
                          pr={3}
                        >
                          Replace all
                        </Text>
                        <Checkbox
                          colorScheme='purple'
                          isChecked={
                            selectedFieldReplacements[fieldGroup.groupItem]
                              ?.length === fieldGroup.items.length
                          }
                          onChange={e => {
                            if (e.target.checked) {
                              setSelectedFieldReplacements(prev => {
                                return {
                                  ...prev,
                                  [fieldGroup.groupItem]: fieldGroup.items,
                                }
                              })
                            } else {
                              setSelectedFieldReplacements(prev => {
                                return {
                                  ...prev,
                                  [fieldGroup.groupItem]: [],
                                }
                              })
                            }
                          }}
                          sx={{
                            '.chakra-checkbox__control': {
                              height: '18px',
                              width: '18px',
                              borderRadius: '3px',
                              borderColor: 'black', // Color of the border
                              _checked: {
                                color: 'white', // Color of the check icon when checked
                                border: 'none',
                                svg: {
                                  transform: 'scale(1.3)', // Scale up the checkmark
                                },
                              },
                              _hover: {
                                borderColor: 'black', // Border color on hover
                              },
                              _focus: {
                                boxShadow: 'none', // Remove the focus outline
                              },
                            },
                          }}
                        />
                      </Flex>
                    </Flex>
                    {fieldGroup.items.map((item, index) => {
                      return (
                        <StyledCommandItem
                          key={`${fieldGroup.groupItem}-item-${index}`}
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <Flex
                            direction={'row'}
                            grow={1}
                            width='100%'
                            alignItems={'center'}
                            justifyContent='space-between'
                            paddingX={1}
                            paddingY={2}
                            _hover={{
                              '.hover-button': { visibility: 'visible' },
                            }}
                            onClick={e => {}}
                          >
                            <Flex direction={'row'} alignItems='center' gap={4}>
                              <Flex direction={'row'}>
                                <Text
                                  fontSize={'14px'}
                                  fontWeight={'400'}
                                  marginRight={'12px'}
                                >
                                  {item.label}
                                </Text>
                                {item.url && (
                                  <Tooltip
                                    label='Open in Salesforce'
                                    color='#202020'
                                    backgroundColor='#F0F0F0'
                                    borderRadius='6px'
                                    border='solid #D9D9D9 1px'
                                    fontSize='12px'
                                    shouldWrapChildren
                                    hasArrow
                                  >
                                    <Button
                                      size='xs'
                                      variant='outline'
                                      className='hover-button'
                                      visibility='hidden'
                                      _hover={{ backgroundColor: 'white' }}
                                      onClick={e => {
                                        e.stopPropagation()
                                        openPage(item.url)
                                      }}
                                    >
                                      <ArrowUpRightIcon
                                        height={12}
                                        width={12}
                                        strokeWidth={3}
                                        stroke='#999999'
                                      />
                                    </Button>
                                  </Tooltip>
                                )}
                              </Flex>
                            </Flex>
                            <Checkbox
                              colorScheme='purple'
                              isChecked={selectedFieldReplacements[
                                fieldGroup.groupItem
                              ]?.includes(item)}
                              onChange={e => {
                                if (e.target.checked) {
                                  setSelectedFieldReplacements(prev => {
                                    return {
                                      ...prev,
                                      [fieldGroup.groupItem]: [
                                        ...(prev[fieldGroup.groupItem] ?? []),
                                        item,
                                      ],
                                    }
                                  })
                                } else {
                                  setSelectedFieldReplacements(prev => {
                                    return {
                                      ...prev,
                                      [fieldGroup.groupItem]: prev[
                                        fieldGroup.groupItem
                                      ].filter(
                                        existingItem => existingItem !== item,
                                      ),
                                    }
                                  })
                                }
                              }}
                              sx={{
                                '.chakra-checkbox__control': {
                                  height: '18px',
                                  width: '18px',
                                  borderRadius: '3px',
                                  borderColor: 'black', // Color of the border
                                  _checked: {
                                    color: 'white',
                                    border: 'none', // Color of the check icon when checked
                                    svg: {
                                      transform: 'scale(1.3)', // Scale up the checkmark
                                    },
                                  },
                                  _hover: {
                                    borderColor: 'black', // Border color on hover
                                  },
                                  _focus: {
                                    boxShadow: 'none', // Remove the focus outline
                                  },
                                },
                              }}
                            />
                          </Flex>
                        </StyledCommandItem>
                      )
                    })}
                  </Command.Group>
                )
              })}
          </Box>
        )}

        <Flex justifyContent='flex-end' pr={2}>
          {!isLoading && (
            <Button
              size='sm'
              variant='primaryCTA'
              isDisabled={
                !Object.values(selectedFieldReplacements).some(
                  arr => arr.length > 0,
                )
              }
              {...(!Object.values(selectedFieldReplacements).some(
                arr => arr.length > 0,
              ) && {
                _hover: {
                  cursor: 'not-allowed',
                  opacity: 0.4,
                },
              })}
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    key: 'replacePicklist',
                    data: {
                      selectedField: selectedField,
                      selectedType: selectedType,
                      references: selectedFieldReplacements,
                    },
                  },
                })
                onConfirm()
              }}
            >
              Replace
            </Button>
          )}
        </Flex>
      </div>
    )
  }

  return <></>
}
