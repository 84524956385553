import {
  Flex,
  Button,
  Checkbox,
  Text,
  Box,
  Portal,
  Center,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'
import { useState, useEffect, useContext } from 'react'
import { Command } from 'cmdk'
import Api from '../../lib/api'
import { StyledCommandItem } from '.'
import { StateStore } from '../Store'
import {
  ArrowUpRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline'

type Props = {
  page: string
  selectedField: { field_name: string; object_name: string }
  onConfirm: () => void
}

export default function FieldReferences(props: Props) {
  const { page, selectedField, onConfirm } = props
  const [expandedGroups, setExpandedGroups] = useState<any>({})

  const { dispatch } = useContext(StateStore)

  const [isLoading, setIsLoading] = useState(true)

  const [fieldReferences, setFieldReferences] = useState<
    Array<{
      groupItem: string
      total_items: number
      items: Array<{ api_name: string; label: string; url: string }>
    }>
  >([])

  const [selectedType, setSelectedType] = useState('')

  const [selectedFieldReplacements, setSelectedFieldReplacements] = useState<{
    [key: string]: Array<{ api_name: string; label: string }>
  }>({})

  const openPage = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  useEffect(() => {
    if (!!selectedField) {
      setIsLoading(true)
      Api.salesforce.customFields
        .fieldReferences({
          fieldName: selectedField?.field_name,
          objectName: selectedField?.object_name,
          isDemoEnv: process.env.NEXT_PUBLIC_APP_ENV === 'staging',
        })
        .then(({ references, type }) => {
          if (Array.isArray(references)) {
            setExpandedGroups(
              references.reduce((acc: any, fieldGroup: any) => {
                acc[fieldGroup.groupItem] =
                  fieldGroup.total_items > 30 ? false : true
                return acc
              }, {}),
            )
            setFieldReferences(references)
            setSelectedType(type)
          }
          setIsLoading(false)
        })
    } else {
      setFieldReferences([])
    }
  }, [selectedField])

  const getGroupLabel = (name: string) => {
    switch (name) {
      case 'ValidationRule':
        return 'Validation Rule'
      case 'ApexClass':
        return 'Apex Class'
      case 'ApexTrigger':
        return 'Apex Trigger'
      default:
        return name
    }
  }

  const handleExpandClick = (id: string) => {
    setExpandedGroups((prevState: any) => {
      const newState = { ...prevState }
      if (newState[id]) {
        delete newState[id]
      } else {
        newState[id] = true
      }
      return newState
    })
  }

  if (page === 'References') {
    return (
      <div
        style={{
          paddingBottom: '10px',
        }}
      >
        {isLoading ? (
          <Center h='200px' w='full'>
            <Spinner />
          </Center>
        ) : !isLoading && fieldReferences?.length === 0 ? (
          <Center h='200px' w='full'>
            No references
          </Center>
        ) : (
          <Box maxHeight='600px' overflowY='scroll'>
            {fieldReferences.map(fieldGroup => {
              return (
                <Command.Group
                  key={fieldGroup.groupItem}
                  style={{ paddingTop: '8px', paddingBottom: '8px' }}
                >
                  <Flex
                    w='full'
                    justifyContent='space-between'
                    alignItems='center'
                    pb={2}
                  >
                    <Text
                      fontSize='15px'
                      color='gray.900'
                      pl={5}
                      fontWeight={500}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {getGroupLabel(fieldGroup.groupItem)}

                      <Tooltip
                        label={`${fieldGroup.total_items} references`}
                        color='#1A202C'
                        backgroundColor='#F0F0F0'
                        borderRadius='6px'
                        border='solid #D9D9D9 1px'
                        fontSize='12px'
                        fontWeight={400}
                        shouldWrapChildren
                        hasArrow
                      >
                        <span
                          style={{
                            color: '#5b5a5a',
                            fontWeight: 300,
                          }}
                        >
                          &nbsp;
                          {`(${fieldGroup.total_items || 0})`}
                        </span>
                      </Tooltip>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#1A202C',
                          marginTop: '0px',
                          marginLeft: '12px',
                          cursor: 'pointer',
                          fontWeight: 500,
                          fontSize: '12px',
                          zIndex: 999999,
                          width: '100%',
                        }}
                        onClick={() => {
                          handleExpandClick(fieldGroup.groupItem)
                        }}
                      >
                        {!expandedGroups[fieldGroup.groupItem] ? (
                          <>
                            <ChevronDownIcon height={16} width={16} />{' '}
                          </>
                        ) : (
                          <>
                            <ChevronUpIcon height={16} width={16} />{' '}
                          </>
                        )}
                      </div>
                    </Text>
                    <Flex mr='21.5px'>
                      <Text
                        fontSize='15px'
                        color='gray.900'
                        fontWeight={400}
                        pr={3}
                      >
                        Replace all
                      </Text>
                      <Checkbox
                        colorScheme='purple'
                        isChecked={
                          selectedFieldReplacements[fieldGroup.groupItem]
                            ?.length === fieldGroup.items.length
                        }
                        onChange={e => {
                          if (e.target.checked) {
                            setSelectedFieldReplacements(prev => {
                              return {
                                ...prev,
                                [fieldGroup.groupItem]: fieldGroup.items,
                              }
                            })
                          } else {
                            setSelectedFieldReplacements(prev => {
                              return {
                                ...prev,
                                [fieldGroup.groupItem]: [],
                              }
                            })
                          }
                        }}
                        sx={{
                          '.chakra-checkbox__control': {
                            height: '18px',
                            width: '18px',
                            borderRadius: '3px',
                            borderColor: 'black', // Color of the border
                            _checked: {
                              color: 'white', // Color of the check icon when checked
                              border: 'none',
                              svg: {
                                transform: 'scale(1.3)', // Scale up the checkmark
                              },
                            },
                            _hover: {
                              borderColor: 'black', // Border color on hover
                            },
                            _focus: {
                              boxShadow: 'none', // Remove the focus outline
                            },
                          },
                        }}
                      />
                    </Flex>
                  </Flex>
                  {expandedGroups[fieldGroup.groupItem] &&
                    [
                      ...fieldGroup.items,
                      //   ...fieldGroup.items,
                      //   ...fieldGroup.items,
                    ].map((item, index) => {
                      return (
                        <StyledCommandItem
                          key={`${fieldGroup.groupItem}-item-${index}`}
                          style={{ backgroundColor: 'transparent' }}
                        >
                          <Flex
                            direction={'row'}
                            grow={1}
                            width='100%'
                            alignItems={'center'}
                            justifyContent='space-between'
                            paddingX={1}
                            paddingY={2}
                            _hover={{
                              '.hover-button': { visibility: 'visible' },
                            }}
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()

                              setSelectedFieldReplacements(prev => {
                                const existingSelected =
                                  prev[fieldGroup.groupItem]
                                return {
                                  ...prev,
                                  [fieldGroup.groupItem]:
                                    existingSelected?.includes(item)
                                      ? existingSelected?.filter(
                                          existingItem => existingItem !== item,
                                        )
                                      : [...(existingSelected ?? []), item],
                                }
                              })
                            }}
                          >
                            <Flex direction={'row'} alignItems='center' gap={4}>
                              <Flex direction={'row'}>
                                <Text
                                  fontSize={'14px'}
                                  fontWeight={'400'}
                                  marginRight={'12px'}
                                >
                                  {item.label}
                                </Text>
                                {item.url && (
                                  <Tooltip
                                    label='Open in Salesforce'
                                    color='#202020'
                                    backgroundColor='#F0F0F0'
                                    borderRadius='6px'
                                    border='solid #D9D9D9 1px'
                                    fontSize='12px'
                                    shouldWrapChildren
                                    hasArrow
                                  >
                                    <Button
                                      size='xs'
                                      variant='outline'
                                      className='hover-button'
                                      visibility='hidden'
                                      _hover={{ backgroundColor: 'white' }}
                                      onClick={e => {
                                        e.stopPropagation()
                                        openPage(item.url)
                                      }}
                                    >
                                      <ArrowUpRightIcon
                                        height={12}
                                        width={12}
                                        strokeWidth={3}
                                        stroke='#999999'
                                      />
                                    </Button>
                                  </Tooltip>
                                )}
                              </Flex>
                            </Flex>
                            <Checkbox
                              colorScheme='purple'
                              isChecked={selectedFieldReplacements[
                                fieldGroup.groupItem
                              ]?.includes(item)}
                              onChange={e => {
                                if (e.target.checked) {
                                  setSelectedFieldReplacements(prev => {
                                    return {
                                      ...prev,
                                      [fieldGroup.groupItem]: [
                                        ...(prev[fieldGroup.groupItem] ?? []),
                                        item,
                                      ],
                                    }
                                  })
                                } else {
                                  setSelectedFieldReplacements(prev => {
                                    return {
                                      ...prev,
                                      [fieldGroup.groupItem]: prev[
                                        fieldGroup.groupItem
                                      ].filter(
                                        existingItem => existingItem !== item,
                                      ),
                                    }
                                  })
                                }
                              }}
                              sx={{
                                '.chakra-checkbox__control': {
                                  height: '18px',
                                  width: '18px',
                                  borderRadius: '3px',
                                  borderColor: 'black', // Color of the border
                                  _checked: {
                                    color: 'white',
                                    border: 'none', // Color of the check icon when checked
                                    svg: {
                                      transform: 'scale(1.3)', // Scale up the checkmark
                                    },
                                  },
                                  _hover: {
                                    borderColor: 'black', // Border color on hover
                                  },
                                  _focus: {
                                    boxShadow: 'none', // Remove the focus outline
                                  },
                                },
                              }}
                            />
                          </Flex>
                        </StyledCommandItem>
                      )
                    })}
                </Command.Group>
              )
            })}
          </Box>
        )}

        <Flex justifyContent='flex-end' pr={2}>
          {!isLoading && (
            <Button
              size='sm'
              variant='primaryCTA'
              isDisabled={
                !Object.values(selectedFieldReplacements).some(
                  arr => arr.length > 0,
                )
              }
              {...(!Object.values(selectedFieldReplacements).some(
                arr => arr.length > 0,
              ) && {
                _hover: {
                  cursor: 'not-allowed',
                  opacity: 0.4,
                },
              })}
              onClick={() => {
                dispatch({
                  type: 'TOGGLE_MODAL',
                  payload: {
                    key: 'replaceField',
                    data: {
                      selectedField: selectedField,
                      selectedType: selectedType,
                      references: selectedFieldReplacements,
                    },
                  },
                })
                onConfirm()
              }}
            >
              Replace
            </Button>
          )}
        </Flex>
      </div>
    )
  }

  return <></>
}
