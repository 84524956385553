import { FieldConfig, useFormikContext } from 'formik'
import {
  Props,
  ChakraStylesConfig,
  createFilter,
  CreatableSelect,
  Select as ChakraReactSelect,
  AsyncSelect,
} from 'chakra-react-select'
import React from 'react'
import get from 'lodash/get'
import OptimizedOption from './OptimizedOption'
import { flatten } from 'lodash'

type SelectProps = Props &
  FieldConfig & {
    creatable?: boolean
    maxItems?: number
    noPadding?: boolean
    maxMenuListHeight?: string
    async?: boolean
    loadOptions?: any
    defaultOptions?: any
  }

export type SelectOption = {
  value: string | number
  label: string
}

export const Select = (props: SelectProps) => {
  const { setFieldValue, values, setTouched } = useFormikContext()
  const { options, name, value } = props

  const currentValue =
    value !== undefined && !get(values, name) ? value : get(values, name)

  const singleValue = !!props.options
    ? props.options
      ? props.options.find(
          option => (option as SelectOption).value === currentValue,
        )
      : ''
    : !!props.defaultOptions
    ? props.defaultOptions
      ? props.defaultOptions.find(
          (option: any) => (option as SelectOption).value === currentValue,
        )
      : ''
    : ''

  const multiValues =
    props.isMulti && Array.isArray(get(values, props.name))
      ? props.creatable
        ? get(values, props.name)
        : (props.options || []).filter(option =>
            get(values, props.name).includes((option as SelectOption).value),
          )
      : ''

  const baseStyles: ChakraStylesConfig = {
    control: styles => ({
      ...styles,
      background: 'white',
      minHeight: 'fit-content',
      maxHeight: '64px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      borderRadius: '4px',
      cursor: 'pointer',
      _focus: {
        outline: 'none !important',
        boxShadow: 'none !important',
        border: '1px solid !important',
        borderColor: 'black !important',
      },
      padding: props.isMulti && !props.noPadding ? '10px 0' : '0px',
    }),
    clearIndicator: styles => ({
      ...styles,
      width: '16px',
      height: '16px',
      position: 'relative',
      top: '2px',
      background: 'gray.200',
      '> svg': {
        width: '8px',
        height: '8px',
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: '12px',
        color: 'red.800',
        _hover: {
          backgroundColor: 'gray.200',
        },
      }
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
      }
    },
    inputContainer: styles => ({
      ...styles,
      color: 'black',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: 'black',
    }),
    multiValueLabel: (styles, { data }) => ({
      ...styles,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
    }),
    container: styles => ({
      ...styles,
      height: 'fit-content',
      width: '100%',
      minHeight: '32px',
      borderRadius: '0px',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    valueContainer: styles => ({
      ...styles,
      padding: '0px 10px 0px 10px',
      borderRadius: '4px',
      gap: '5px',
      cursor: 'pointer',
      fontSize: 'xs',
      color: 'black',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    indicatorsContainer: styles => ({
      ...styles,
      padding: '5px 0',
      background: 'none !important',
      alignItems: 'flex-start',
      paddingRight: '5px',
    }),
    dropdownIndicator: styles => ({
      ...styles,
      alignItems: 'flex-start',
      position: 'relative',
      bottom: '1px',
      background: 'none !important',
      color: '#BBBBBB',
      svg: {
        strokeWidth: '1px !important',
      },
      paddingLeft: '0px',
      paddingRight: '0px',
    }),
    input: styles => ({
      ...styles,
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: '12px',
    }),
    menu: styles => ({
      ...styles,
      fontSize: 'sm !important',
    }),
    menuList: styles => ({
      ...styles,
      fontSize: 'sm !important',
      maxHeight: props.maxMenuListHeight ? props.maxMenuListHeight : '290px',
    }),
    noOptionsMessage: styles => ({
      ...styles,
      fontSize: '12px',
      paddingY: '1px',
    }),
  }

  return props.creatable ? (
    <CreatableSelect
      options={props.options}
      className={props.className}
      chakraStyles={baseStyles}
      classNamePrefix='custom-select'
      isDisabled={props.isDisabled}
      escapeClearsValue={true}
      components={{ Option: OptimizedOption }}
      onCreateOption={e =>
        setFieldValue(props.name, [
          ...(get(values, props.name) as Array<any>),
          {
            value: e,
            label: e,
          },
        ])
      }
      placeholder={props.placeholder}
      name={props.name}
      filterOption={createFilter({ ignoreAccents: false })}
      isMulti={props.isMulti}
      isOptionDisabled={option => {
        return (
          (props.isMulti &&
            multiValues &&
            multiValues.length > (props.maxItems || 100)) ||
          option.disabled ||
          false
        )
      }}
      value={props.isMulti ? multiValues : singleValue}
      onChange={(option, e) => {
        if (props.isMulti) {
          if (e.action === 'remove-value' || e.action === 'pop-value') {
            if (props.creatable) {
              setFieldValue(
                props.name,
                (get(values, props.name) as Array<any>).filter(
                  g => g !== e.removedValue,
                ),
              )
            } else {
              setFieldValue(
                props.name,
                (get(values, props.name) as Array<any>).filter(
                  e => e !== (option as SelectOption).value,
                ),
              )
            }
          } else if (
            e.action === 'create-option' ||
            e.action === 'select-option'
          ) {
            setFieldValue(props.name, [
              ...(get(values, props.name) as Array<any>),
              {
                value: (option as SelectOption).value,
                label: (option as SelectOption).label,
              },
            ])
          } else if (e.action === 'clear') {
            setFieldValue(props.name, [])
          }
        } else {
          setFieldValue(props.name, (option as SelectOption).value)
        }
      }}
      formatCreateLabel={e => (
        <div className='w-full flex flex-row cursor-pointer'>Create {e}</div>
      )}
      onBlur={props.onBlur}
    />
  ) : props.async ? (
    <AsyncSelect
      loadOptions={props.loadOptions}
      defaultOptions={props.defaultOptions}
      className={props.className}
      chakraStyles={baseStyles}
      classNamePrefix='custom-select'
      isDisabled={props.isDisabled}
      escapeClearsValue={true}
      components={{ Option: OptimizedOption }}
      placeholder={props.placeholder}
      name={props.name}
      filterOption={createFilter({ ignoreAccents: false })}
      isMulti={props.isMulti}
      value={props.isMulti ? multiValues : singleValue || ''}
      isOptionDisabled={option => {
        return (
          (props.isMulti &&
            multiValues &&
            multiValues.length > (props.maxItems || 0)) ||
          option.disabled ||
          false
        )
      }}
      onChange={(option, e) => {
        if (props.isMulti) {
          if (e.action === 'remove-value' || e.action === 'pop-value') {
            setFieldValue(
              props.name,
              (get(values, props.name) as Array<any>).filter(
                g => g !== get(e, 'removedValue.value'),
              ),
            )
          } else if (e.action === 'clear') {
            setFieldValue(
              props.name,
              (get(values, props.name) as Array<any>).filter(
                g =>
                  !get(e, 'removedValues')
                    .map((p: any) => p.value)
                    .includes(g),
              ),
            )
          } else if (
            e.action === 'create-option' ||
            e.action === 'select-option'
          ) {
            setFieldValue(
              props.name,
              flatten([
                ...(get(values, props.name) as Array<any>),
                (option as Array<SelectOption>).map(e => e.value),
              ]),
            )
          }
        } else {
          setFieldValue(props.name, (option as SelectOption).value)
        }

        setTouched({ [props.name]: true })
      }}
      onBlur={props.onBlur}
    />
  ) : (
    <ChakraReactSelect
      options={props.options}
      className={props.className}
      chakraStyles={baseStyles}
      classNamePrefix='custom-select'
      isDisabled={props.isDisabled}
      escapeClearsValue={true}
      components={{ Option: OptimizedOption }}
      placeholder={props.placeholder}
      name={props.name}
      filterOption={createFilter({ ignoreAccents: false })}
      isMulti={props.isMulti}
      value={props.isMulti ? multiValues : singleValue || ''}
      isOptionDisabled={option => {
        return (
          (props.isMulti &&
            multiValues &&
            multiValues.length > (props.maxItems || 0)) ||
          option.disabled ||
          false
        )
      }}
      onChange={(option, e) => {
        if (props.isMulti) {
          if (e.action === 'remove-value' || e.action === 'pop-value') {
            setFieldValue(
              props.name,
              (get(values, props.name) as Array<any>).filter(
                g => g !== get(e, 'removedValue.value'),
              ),
            )
          } else if (e.action === 'clear') {
            setFieldValue(
              props.name,
              (get(values, props.name) as Array<any>).filter(
                g =>
                  !get(e, 'removedValues')
                    .map((p: any) => p.value)
                    .includes(g),
              ),
            )
          } else if (
            e.action === 'create-option' ||
            e.action === 'select-option'
          ) {
            setFieldValue(
              props.name,
              flatten([
                ...(get(values, props.name) as Array<any>),
                (option as Array<SelectOption>).map(e => e.value),
              ]),
            )
          }
        } else {
          setFieldValue(props.name, (option as SelectOption).value)
        }

        setTouched({ [props.name]: true })
      }}
      onBlur={props.onBlur}
    />
  )
}

export default Select
