import Api from '../../lib/api'
import { useEffect, useState } from 'react'
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react'
import SaveListViewModal from './SaveListViewModal'
import { ListViewOption } from './ListViewSelector'
import { CiViewList } from 'react-icons/ci'
import { RiPlayListAddFill } from 'react-icons/ri'
import { IoRefresh } from 'react-icons/io5'
import { MdSaveAlt } from 'react-icons/md'
import { TbSwitchHorizontal } from 'react-icons/tb'

type Props = {} & any

export default function ListViewOptionsMenu(props: Props) {
  const [listViewMode, setListViewMode] = useState('menu')
  const [saveListViewModalOpen, setSaveListViewModalOpen] = useState(false)
  const [listViewModalAction, setListViewModalAction] = useState<
    'create' | 'update' | null
  >(null)

  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const foundListView = props.listViews?.find(
    (listView: any) => listView.id === props.selectedListViewId,
  )

  const selectedListViewName = foundListView?.name

  const defaultListView = props.listViews?.find(
    (listView: any) =>
      listView.object_name === props.objectType && listView.is_default,
  )
  
  return (
    <>
      <Popover
        placement='bottom-start'
        onClose={() => {
          setListViewMode('menu')
        }}
      >
        {({ onClose }) => {
          return (
            <>
              <PopoverTrigger>
                <Box
                  display='flex'
                  alignItems='center'
                  borderRadius='md'
                  fontSize='xs'
                  bgColor='white'
                  border='1px'
                  borderColor='#e4e4e7'
                  px='3'
                  py='1'
                  fontWeight='medium'
                  cursor='pointer'
                  lineHeight='tight'
                  whiteSpace='nowrap'
                  maxW='200px'
                  _hover={{ bgColor: '#fafafa' }}
                  {...props} // Allows you to pass additional props to the Box component
                >
                  <CiViewList size={19} strokeWidth={1} />
                  <Text
                    w='full'
                    style={{ paddingLeft: '8px', fontWeight: 500 }}
                    noOfLines={1}
                    title={`${
                      !!selectedListViewName ? selectedListViewName : 'Default'
                    } View`}
                  >
                    {`${
                      !!selectedListViewName ? selectedListViewName : 'Default'
                    } View`}
                  </Text>
                </Box>
              </PopoverTrigger>
              <PopoverContent
                w={listViewMode === 'menu' ? '200px' : 'auto'}
                minWidth={'200px'}
              >
                {listViewMode === 'menu' ? (
                  <>
                    {props.listViews?.filter(
                      (view: any) => view.object_name === props.objectType,
                    )?.length > 0 && (
                      <ListViewOption
                        fontSize='13px'
                        onClick={() => {
                          setListViewMode('toggle')
                        }}
                      >
                        <TbSwitchHorizontal />
                        <Text pl={1}>Switch View </Text>
                      </ListViewOption>
                    )}
                    <ListViewOption
                      fontSize='13px'
                      onClick={() => {
                        setSaveListViewModalOpen(true)
                        setListViewModalAction('create')
                        onClose()
                      }}
                    >
                      <RiPlayListAddFill />
                      <Text pl={1}> {'Create New List View'}</Text>
                    </ListViewOption>
                    {props.selectedListViewId &&
                      !(
                        !(props.user.is_manager || props.user.is_super_admin) &&
                        !!foundListView?.org_wide
                      ) && (
                        <ListViewOption
                          fontSize='13px'
                          onClick={() => {
                            setSaveListViewModalOpen(true)
                            setListViewModalAction('update')
                            onClose()
                          }}
                        >
                          <MdSaveAlt />
                          <Text
                            pl={1}
                            w='full'
                            noOfLines={1}
                            title={selectedListViewName}
                          >
                            {`Update ${selectedListViewName}${
                              selectedListViewName
                                ?.toLowerCase()
                                .includes('view')
                                ? ''
                                : ' view'
                            }`}{' '}
                          </Text>
                        </ListViewOption>
                      )}
                    {props.listViews?.filter(
                      (view: any) => view.object_name === props.objectType,
                    )?.length > 0 && (
                      <ListViewOption
                        fontSize='13px'
                        onClick={() => {
                          setIsLoading(true)
                          Api.listViews.newFields().then(({ list_views }) => {
                            Api.salesforce.objects.objectName.fields
                              .getFields({ objectName: props.objectType })
                              .then(({ fields }) => {
                                toast({
                                  description: 'Fields refreshed',
                                  status: 'success',
                                  position: 'bottom-right',
                                  containerStyle: {
                                    marginBottom: '40px',
                                    marginRight: '40px',
                                  },
                                })
                                onClose()
                                setIsLoading(false)
                                props.updateListViews(list_views, fields)
                              })
                          })
                        }}
                      >
                        <IoRefresh />
                        <Box pl={1}>
                          {isLoading ? 'Refreshing...' : 'Refresh Fields'}
                        </Box>
                      </ListViewOption>
                    )}{' '}
                  </>
                ) : (
                  <>
                    {(defaultListView
                      ? []
                      : [{ name: 'Default View', id: 'default' }]
                    )
                      .concat(
                        props.listViews?.filter(
                          (view: any) => view.object_name === props.objectType,
                        ),
                      )
                      .map((listView: any) => (
                        <ListViewOption
                          key={listView.id}
                          selected={
                            listView.id === props.selectedListViewId ||
                            (!props.selectedListViewId &&
                              listView.id === 'default')
                          }
                          onClick={() => {
                            if (listView.id === 'default') {
                              props.onListViewSelection(undefined)
                            } else {
                              props.onListViewSelection(listView.id)
                            }
                            onClose()
                          }}
                        >
                          {listView.name}
                        </ListViewOption>
                      ))}
                  </>
                )}
              </PopoverContent>
            </>
          )
        }}
      </Popover>
      <SaveListViewModal
        listViewModalAction={listViewModalAction ?? ''}
        isOpen={saveListViewModalOpen}
        setIsOpen={setSaveListViewModalOpen}
        teams={props.teams}
        teamsToColors={props.teamsToColors}
        onSubmit={(
          name: string,
          isDefault: boolean,
          remove: boolean,
          isOrgWide: boolean,
          teamIds: any[],
        ) => {
          props.onSaveListView(
            name,
            isDefault,
            listViewModalAction === 'create',
            remove,
            isOrgWide,
            teamIds,
          )
        }}
        selectedListViewId={props.selectedListViewId}
        listViews={props.listViews}
        objectName={props.objectType}
        user={props.user}
        savedFilters={props.savedFilters}
        onSetSavedFilters={props.onSetSavedFilters}
      />
    </>
  )
}
