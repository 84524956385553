import {
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react'
import styled from 'styled-components'
import { HTMLProps, useEffect, useRef, useState } from 'react'
import { tw } from '@minupalaniappan/brise'
import pluralize from 'pluralize'
import { CiViewList } from 'react-icons/ci'
import { TrashIcon } from '@heroicons/react/24/outline'
import TeamMultiSelect from '../Alerts/V2/TeamMultiSelect'

const Title = styled.div`
  font-size: 21px;
  font-weight: 600;
`

type SavedFilter = {
  id: string;
  name: string;
  filters: any; // Adjust the type as needed to match your filters structure

};

const SaveButton = tw.div<HTMLProps<HTMLDivElement>>`
          cursor-pointer
          rounded-[6px]
          bg-[black]
          text-center
          text-white
          hover:bg-[#282828]
          px-5
          py-2
          text-[14px]
          font-[500]
          ${props => (props.disabled ? `opacity-50 pointer-events-none` : '')}
          `

const DeleteButton = styled.div`
  border-radius: 6px;
  background-color: #ebebeb;
  color: #1a202c;
  width: 150px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 15px;
`

const Input = styled.input`
  width: 320px;
  height: 45px;
  padding: 8px;
  padding-left: 15px;
  border: 1px solid #e4e4e7;
  border-radius: 6px;
  color: #646464;
  font-weight: 500;
  font-size: 13px;
  margin-top: 35px;
`
const Label = styled.div`
  font-size: 13px;
  color: #5b5a5a;
  font-weight: 500;
  margin-bottom: 8px;
`

function modalTitle(objectName: string) {
  const pluralizedName = pluralize(objectName, 2)

  const vowels = ['a', 'e', 'i', 'o', 'u']
  let article = 'a'
  if (vowels.includes(objectName[0].toLowerCase())) {
    article = 'an'
  }

  return `Save your current view as ${article} ${pluralizedName} list view`
}

const SaveListViewModal = ({
  isOpen,
  setIsOpen,
  onSubmit,
  teams,
  teamsToColors,
  selectedListViewId,
  listViews,
  objectName,
  listViewModalAction,
  user,
  savedFilters,
  onSetSavedFilters,
}: {
  isOpen: boolean
  setIsOpen: (arg: boolean) => void
  teams: any[]
  teamsToColors: any
  onSubmit: (
    listViewName: string,
    isDefault: boolean,
    remove: boolean,
    isOrgWide: boolean,
    teamIds: any[],
  ) => void
  selectedListViewId: string | undefined
  listViews: any[]
  objectName: string
  listViewModalAction: string
  user: any
  savedFilters: SavedFilter[]
  onSetSavedFilters: Function
}) => {
  const [listViewName, setListViewName] = useState<string>('')
  const [isDefault, setIsDefault] = useState<boolean>(false)
  const [isOrgWide, setIsOrgWide] = useState<boolean>(false)
  const [teamSelections, setTeamSelections] = useState<string[]>([])

  useEffect(() => {
    if (listViewModalAction === 'create') {
      setListViewName('')
      setIsDefault(false)
      setIsOrgWide(false)
      setTeamSelections([])
    } else {
      if (!selectedListViewId) {
        setListViewName('')
      } else {
        const listView = listViews?.find(
          (view: any) => view.id === selectedListViewId,
        )

        setListViewName(listView?.name || '')
        setIsDefault(listView?.is_default)
        setIsOrgWide(listView?.org_wide)
        setTeamSelections(listView?.team_ids || [])
      }
    }
  }, [selectedListViewId, listViews, listViewModalAction])

  const submitEnabled = () => {
    if (!listViewName) {
      return false
    }
    return true
  }

  const handleClose = () => {
    if (listViewModalAction === 'create') {
      setListViewName('')
      setIsDefault(false)
      setIsOrgWide(false)
      setTeamSelections([])
      setIsOpen(false)
    } else {
      setIsOpen(false)
    }
  }

  const handleTeamsSelection = (id: string) => {
    setTeamSelections(prevSelections => {
      if (prevSelections.includes(id)) {
        return prevSelections.filter(existingId => existingId !== id)
      } else {
        return [...prevSelections, id]
      }
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        handleClose()
      }}
      closeOnEsc
      closeOnOverlayClick
      isCentered
    >
      <ModalOverlay />
      <ModalContent minHeight={'250px'} width={'360px'} borderRadius={'10px'}>
        <ModalBody style={{ margin: '2px', marginTop: '2px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CiViewList size={26} />
            <Title style={{ marginLeft: '8px' }}>
              {listViewModalAction === 'update'
                ? 'Update List View'
                : 'Create List View'}
            </Title>
          </div>

          <div
            style={{
              fontSize: '12px',
              fontWeight: '500',
              color: '#646464',
              marginLeft: '2px',
              marginTop: '4px',
            }}
          >
            {listViewModalAction === 'update'
              ? 'Update your saved list view'
              : modalTitle(objectName)}
          </div>

          <Input
            value={listViewName || ''}
            placeholder='List view name'
            onChange={e => setListViewName(e.target.value)}
          />

          {(user.is_manager || user.is_super_admin) && (
            <Flex justifyContent='space-between' mb={3} mt={3} pr='5px'>
              <div style={{ marginBottom: '10px', width: '434px' }}>
                <TeamMultiSelect
                  fontSize={'13px'}
                  fontColor={'#9BA3AF'}
                  teams={teams}
                  teamsToColors={'black'}
                  selections={teamSelections}
                  onSelect={handleTeamsSelection}
                  fontWeight={500}
                />
              </div>
            </Flex>
          )}
          <Flex
            justifyContent='space-between'
            mb={user.is_manager || user.is_super_admin ? 2 : 5}
            mt={5}
            pr='5px'
          >
            <Text style={{ fontSize: '14px' }}>
              Set this view as the default view?
            </Text>

            <Checkbox
              colorScheme='purple'
              isChecked={isDefault}
              onChange={e => {
                setIsDefault(e.target.checked)
              }}
              sx={{
                '.chakra-checkbox__control': {
                  height: '18px',
                  width: '18px',
                  borderRadius: '3px',
                  borderColor: 'black', // Color of the border
                  _checked: {
                    color: 'white',
                    border: 'none', // Color of the check icon when checked
                    svg: {
                      transform: 'scale(1.3)', // Scale up the checkmark
                    },
                  },
                  _hover: {
                    borderColor: 'black', // Border color on hover
                  },
                  _focus: {
                    boxShadow: 'none', // Remove the focus outline
                  },
                },
              }}
            />
          </Flex>
        </ModalBody>
        <ModalFooter
          sx={{
            paddingTop: 0,
            borderTop: 0,
            display: 'flex',
            justify: 'space-between',
          }}
        >
          {listViewModalAction === 'update' ? (
            <>
              <DeleteButton
                onClick={() => {
                  if (!!listViewName) {
                    onSubmit(
                      listViewName,
                      isDefault,
                      true,
                      teamSelections?.includes('orgWide'),
                      teamSelections,
                    )
                    handleClose()
                  }
                }}
                style={{ width: '110px' }}
              >
                <TrashIcon
                  className='w-[18px] h-[18px]'
                  style={{ marginRight: '5px' }}
                ></TrashIcon>
                {'Delete'}
              </DeleteButton>
              <Spacer />
              <SaveButton
                disabled={!submitEnabled()}
                onClick={() => {
                  if (!!listViewName) {
                    onSubmit(
                      listViewName,
                      isDefault,
                      false,
                      teamSelections?.includes('orgWide'),
                      teamSelections,
                    )
                    handleClose()
                  }
                }}
                style={{ width: '110px' }}
              >
                {'Save'}
              </SaveButton>
            </>
          ) : (
            <SaveButton
              disabled={!submitEnabled()}
              onClick={() => {
                if (!!listViewName) {
                  onSubmit(
                    listViewName,
                    isDefault,
                    false,
                    teamSelections?.includes('orgWide'),
                    teamSelections,
                  )
                  handleClose()
                }
              }}
              style={{ width: '100%' }}
            >
              {'Save'}
            </SaveButton>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SaveListViewModal
