import Api from '../../lib/api'
import { useState } from 'react'
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react'
import SaveListViewModal from './SaveListViewModal'
import { ListViewOption } from './ListViewSelector'
import { RiPlayListAddFill } from 'react-icons/ri'
import { MdSaveAlt } from 'react-icons/md'

type Props = {} & any

export default function ListViewUpdateCreateMenu(props: Props) {
  const [listViewMode, setListViewMode] = useState('menu')
  const [saveListViewModalOpen, setSaveListViewModalOpen] = useState(false)
  const [listViewModalAction, setListViewModalAction] = useState<
    'create' | 'update' | null
  >(null)

  const toast = useToast()

  const foundListView = props.listViews?.find(
    (listView: any) => listView.id === props.selectedListViewId,
  )

  const selectedListViewName = foundListView?.name

  return (
    <>
      <Popover
        placement="bottom-start"
        onClose={() => {
          setListViewMode('menu')
        }}
      >
        {({ onClose }) => {
          return (
            <>
              <PopoverTrigger>
                <Box
                  display="flex"
                  alignItems="center"
                  borderRadius="md"
                  fontSize="xs"
                  bgColor="white"
                  border="1px"
                  borderColor="#e4e4e7"
                  px="3"
                  py="1"
                  fontWeight="medium"
                  cursor="pointer"
                  lineHeight="tight"
                  whiteSpace="nowrap"
                  maxW="200px"
                  _hover={{ bgColor: '#fafafa' }}
                  {...props}
                >
                  <Text
                    w="full"
                    style={{ paddingLeft: '0px', fontWeight: 500 }}
                    noOfLines={1}
                  >
                    Save List View
                  </Text>
                </Box>
              </PopoverTrigger>
              <PopoverContent
                w={listViewMode === 'menu' ? '200px' : 'auto'}
                minWidth={'200px'}
              >
                {listViewMode === 'menu' ? (
                  <>
                    {/* Create New List View Option */}
                    <ListViewOption
                      fontSize="13px"
                      onClick={() => {
                        setSaveListViewModalOpen(true)
                        setListViewModalAction('create')
                        onClose()
                      }}
                    >
                      <RiPlayListAddFill />
                      <Text pl={1}> Create New List View</Text>
                    </ListViewOption>

                    {/* Update Selected List View Option */}
                    {props.selectedListViewId &&
                      !(
                        !(props.user.is_manager || props.user.is_super_admin) &&
                        !!foundListView?.org_wide
                      ) && (
                        <ListViewOption
                          fontSize="13px"
                          onClick={() => {
                            setSaveListViewModalOpen(true)
                            setListViewModalAction('update')
                            onClose()
                          }}
                        >
                          <MdSaveAlt />
                          <Text
                            pl={1}
                            w="full"
                            noOfLines={1}
                            title={selectedListViewName}
                          >
                            {`Update ${selectedListViewName}${
                              selectedListViewName
                                ?.toLowerCase()
                                .includes('view')
                                ? ''
                                : ' view'
                            }`}
                          </Text>
                        </ListViewOption>
                      )}
                  </>
                ) : null}
              </PopoverContent>
            </>
          )
        }}
      </Popover>

      {/* Save List View Modal */}
      <SaveListViewModal
        listViewModalAction={listViewModalAction ?? ''}
        isOpen={saveListViewModalOpen}
        setIsOpen={setSaveListViewModalOpen}
        teams={props.teams}
        teamsToColors={props.teamsToColors}
        onSubmit={(
          name: string,
          isDefault: boolean,
          remove: boolean,
          isOrgWide: boolean,
          teamIds: any[],
        ) => {
          props.onSaveListView(
            name,
            isDefault,
            listViewModalAction === 'create',
            remove,
            isOrgWide,
            teamIds,
          )
        }}
        selectedListViewId={props.selectedListViewId}
        listViews={props.listViews}
        objectName={props.objectType}
        user={props.user}
        savedFilters={props.savedFilters}
        onSetSavedFilters={props.onSetSavedFilters}
      />
    </>
  )
}
